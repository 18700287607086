import { LoadingButton, LoadingButtonProps } from "@mui/lab"

import { styled } from "@mui/material/styles";

interface IGPUILoadingButton extends LoadingButtonProps {}

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
    textTransform: "none",
})) as typeof LoadingButton;

function GPUILoadingButton(props: IGPUILoadingButton) {
    const { children, ...restProps } = props;

    return <StyledLoadingButton {...restProps}>{children}</StyledLoadingButton>;
}

GPUILoadingButton.defaultProps = {
    variant: "contained",
};

export default GPUILoadingButton;
