import { FC } from "react";
import trans from "../../../helpers/trans";
import Section from "../../components/Section";

const openHours: any = {
    Mon: "close",
    Tues: "1pm-10pm",
    Wed: "close",
    Thurs: "9am-10pm",
    Fri: "4pm-11pm",
    Sat: "2pm-12pm",
    Sun: "10am-10pm",
};

const ApningstiderDetailsPage: FC = () => {
    return (
        <Section>
            <div className="mx-auto w-full max-w-sm">
                {/* <Link
                    to="/apningstider"
                    className="underline flex h-5 gap-1 items-center hover:text-fi-yellow transition-all"
                >
                    <ArrowLeftIcon className="h-5 w-5 " aria-hidden="true" />
                    {trans("global.button.back")}
                </Link> */}
                <h1 className="text-3xl uppercase font-bold tracking-tighter mt-4">
                    {trans("page.openHours.topHeader")}
                </h1>
                {/* <p className="text-fi-yellow font-semibold text-xl my-4">
                    location
                </p> */}
                <div>
                    {Object.keys(openHours).map((key) => {
                        return (
                            <div
                                key={key}
                                className="flex border-t py-4 px-2 border-gray-200"
                            >
                                <p className="basis-1/2 uppercase font-semibold text-lg">
                                    {key}
                                </p>
                                <p className="basis-1/2 uppercase font-semibold text-lg">
                                    {openHours[key]}
                                </p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Section>
    );
};

export default ApningstiderDetailsPage;
