import React from "react";
import { cloneDeep, get, isEqual, set } from "lodash";
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";

import FormContext from "./FormContext";
import Unsave from "./Unsave";
interface IGPForm {
    onChanged?: Function;
    children: any;
    fields: {};
    data: { [key: string]: any };
    onSubmit: Function;
    errors: object;
    unsaved?: boolean;
    loading?: boolean;
    preventLeftForm?: boolean;
    onSuccess?: Function;
}

export interface IGPFormRef {
    appendData: Function;
    getData: Function;
    onChange: Function;
    onSubmit: Function;
    refreshFormData: Function;
}

export type IFormChangeResponse = {
    id: string;
    value: any;
};

const prepareData = (props: IGPForm) => {
    return parseField(props.fields, props.data);
};

const parseField = (fields = {}, data = {}, prefix = "") => {
    let formData = {};

    for (let i in fields) {
        //@ts-ignore
        const field = fields[i];

        if (field.hasChilds) {
            let tempPrefix = "";
            if (prefix.length > 0) {
                tempPrefix += prefix + ".";
            }
            tempPrefix += i + ".";

            //@ts-ignore
            formData[i] = parseField(field.fields, data, tempPrefix);
            continue;
        }

        let value = get(data, prefix + i);

        if (value === undefined) {
            if (field.default !== undefined) {
                value = field.default;
            } else {
                value = "";
            }
        } else if (value === null && field.type === "string") {
            value = "";
        } else if (field.type === "boolean") {
            value = Boolean(value);
        }

        //@ts-ignore
        formData[i] = value;
    }

    return formData;
};

const GPForm = forwardRef<any, IGPForm>((props, ref) => {
    const { children, onChanged } = props;

    const data = prepareData(props);

    const [state, setState] = useState({
        changed: false,
        data: data,
        dataOrg: data,
        success: false,
    });
    const prevStateData = useRef(data);

    useEffect(() => {
        if (!!onChanged) {
            onChanged(state.data, prevStateData.current);
        }

        prevStateData.current = state.data;
    }, [onChanged, state.data]);

    useImperativeHandle(ref, () => ({
        appendData,
        getData() {
            return state.data;
        },
        onChange,
        onSubmit,
        refreshFormData,
    }));

    const appendData = (newData: any) => {
        setState((prev) => ({
            ...prev,
            data: {
                ...prev.data,
                ...newData,
            },
        }));
    };

    const onChange = (newData: IFormChangeResponse[]) => {
        if (!Array.isArray(newData)) {
            newData = [newData];
        }

        let tempData = cloneDeep(state.data);

        for (let item of newData) {
            tempData = set(tempData, item.id, item.value);
        }

        setState({
            ...state,
            changed: !isEqual(state.dataOrg, tempData),
            data: tempData,
        });
    };

    const onSubmit = (
        event: React.FormEvent<HTMLFormElement | HTMLButtonElement>,
        force = false
    ) => {
        event.preventDefault();
        if (!!props.onSubmit && (state.changed || force)) {
            //@ts-ignore
            props.onSubmit(state.data, onSuccess);
        }
    };

    const onSuccess = () => {
        setState({
            ...state,
            changed: false,
            success: true,
        });
    };

    const getFieldError = (field: string) => {
        return get(props.errors, field, "");
    };

    const getFieldSettings = (field: string) => {
        return get(props.fields, field, {});
    };

    const getParam = (param: string) => {
        return get(state.data, param);
    };

    // const renderPreventLeftForm = () => {
    //     if (!preventLeftForm) {
    //         return null;
    //     }

    //     return (
    //         <Prompt
    //             when={state.changed}
    //             message="Your changes are not saved!"
    //         />
    //     );
    // };

    const refreshFormData = (newData: { [key: string]: any }) => {
        const data = prepareData({ ...props, data: newData });

        setState({
            changed: false,
            data: data,
            dataOrg: data,
            success: false,
        });

        prevStateData.current = data;
    };

    const renderUnsaved = () => {
        if (!props.unsaved || !state.changed) {
            return null;
        }

        return (
            <Unsave
                onSubmit={onSubmit}
                loading={props.loading ? props.loading : false}
            />
        );
    };

    const context = {
        appendData,
        changed: state.changed,
        data: state.data,
        dataAll: props.data,
        getFieldError,
        getFieldSettings,
        getParam,
        onChange,
        onSubmit,
    };

    return (
        <form onSubmit={onSubmit}>
            {/* {renderPreventLeftForm()} */}
            {renderUnsaved()}

            <FormContext.Provider value={context}>
                {typeof children === "function" ? children(context) : children}
            </FormContext.Provider>
            <button
                type="submit"
                style={{
                    height: 0,
                    margin: 0,
                    padding: 0,
                    position: "absolute",
                    visibility: "hidden",
                }}
            >
                Save
            </button>
        </form>
    );
});

GPForm.defaultProps = {
    data: {},
    errors: {},
    loading: false,
    preventLeftForm: true,
    unsaved: true,
};

export default GPForm;
