import trans from "../../../helpers/trans";
import { Loader } from "../../../ui";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const IndexPage: FC = () => {
    const indexImage = useSelector(
        (state: any) => state.app.settings.indexImage
    );
    const onlineBookingUrl = useSelector(
        (state: any) => state.app.settings?.onlineBookingUrl
    );

    const lang = localStorage.getItem("lang");

    if (!indexImage)
        return <Loader background="#003d2d" customColor="#fbc243" />;

    return (
        <div
            style={{ backgroundImage: `url(${indexImage})` }}
            className="w-full h-[calc(100vh-144px)] bg-cover bg-center flex justify-center items-center"
        >
            <div className="absolute inset-0 bg-black opacity-60 h-[calc(100vh-144px)] top-[144px] "></div>
            <div className="flex justify-center items-center flex-col flex-wrap z-1 gap-8">
                <div className="text-5xl text-center">
                    {trans("page.indexPage.title")}
                </div>
                {onlineBookingUrl && (
                    <NavLink
                        to={`${onlineBookingUrl}?locale=${lang}`}
                        target="_blank"
                    >
                        <button className="text-2xl flex flex-row gap-2 items-center shadow-lg hover:shadow-2xl text-black bg-fi-yellow hover:bg-fil-yellow-1 focus:bg-fi-yellow-2 px-5 py-2.5 text-center m-2 rounded-full transition duration-150 ease-in-out">
                            <CalendarDaysIcon className="h-6 w-6 text-black  cursor-pointer" />
                            {trans("page.indexPage.button")}
                        </button>
                    </NavLink>
                )}
            </div>
        </div>
    );
};

export default IndexPage;
