import { AxiosResponse } from "axios";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import { request } from "../api/apiSaga";
import { APP_INIT, APP_LOADED } from "../types";
import { localeChange, settings } from "./actions";
import api from "./api";
import { LOCALE_REQUEST, LOCALE_SUCCESS, SETTINGS_REQUEST } from "./types";
import { AnyAction } from "redux";
import { eventsSagas } from "./pages/Program/saga";
import { INIT_ADMIN } from "./Admin/types";

function* initApp() {
    yield delay(250);
    const storageLang = localStorage.getItem("lang");

    yield call(
        changeLocale,
        localeChange({
            params: {
                locale:
                    storageLang === null || storageLang === "null"
                        ? document.documentElement.lang
                        : storageLang,
            },
        })
    );
    yield call(fetchSettings, settings());

    if (
        window.location.href.includes("admin") ||
        window.location.href.includes("?uuid")
    ) {
        yield put({ type: INIT_ADMIN });
    } else {
        yield put({
            type: APP_LOADED,
        });
    }
}

function* changeLocale(action: any) {
    const response: AxiosResponse = yield call(
        request,
        api.locale,
        action,
        "get"
    );

    yield put({ type: LOCALE_SUCCESS });
    if (response.status < 300) {
        window.translate = response.data.data;
        document.documentElement.lang = action.axiosConfig.config.params.locale;
        localStorage.setItem("lang", action.axiosConfig.config.params.locale);
    }

    return response;
}
function* fetchSettings(action: AnyAction) {
    yield call(request, api.settings, action);
}

export const appSagas = [
    ...eventsSagas,
    takeLatest(LOCALE_REQUEST, changeLocale),
    takeLatest(SETTINGS_REQUEST, fetchSettings),
    takeLatest(APP_INIT, initApp),
];
