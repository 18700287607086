import { Dialog, Popover } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import trans from "../../helpers/trans";
import LangSwitch from "./LangSwitch";
import StyledNavLink from "./StyledNavLink";
import { useSelector } from "react-redux";

const Header: FC = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const onlineBookingUrl = useSelector(
        (state: any) => state.app.settings?.onlineBookingUrl
    );

    const lang = localStorage.getItem("lang");

    return (
        <header className="bg-fi-green tracking-tighter shadow-2xl fixed w-full z-10">
            <nav
                className="mx-auto flex max-w-7xl items-center justify-between p-2 lg:px-8"
                aria-label="Global"
            >
                <div className="flex lg:flex-1">
                    <Link to="/">
                        <span className="sr-only">Your Company</span>
                        <img
                            className="h-32 w-auto"
                            src={require("../../assets/logo.png")}
                            alt="logo"
                        />
                    </Link>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white "
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon
                            className="h-10 w-10 hover:text-fi-yellow"
                            aria-hidden="true"
                        />
                    </button>
                </div>
                <div className="hidden lg:flex justify-between items-end flex-col  gap-4 self-end">
                    <LangSwitch styles={`hidden lg:flex gap-4`} />
                    <Popover.Group className="flex pb-5 lg:gap-x-12">
                        <StyledNavLink
                            to="/program"
                            className="text-2xl uppercase font-bold leading-6 text-white hover:text-fi-yellow transition-all"
                        >
                            {trans("page.program.topHeader")}
                        </StyledNavLink>
                        {onlineBookingUrl && (
                            <StyledNavLink
                                to={`${onlineBookingUrl}?locale=${lang}`}
                                target="_blank"
                                className="text-2xl whitespace-nowrap uppercase font-bold leading-6 text-white hover:text-fi-yellow transition-all"
                            >
                                {trans("page.bookTable.topHeader")}
                            </StyledNavLink>
                        )}
                        <StyledNavLink
                            to="/quiz"
                            className="text-2xl uppercase font-bold leading-6 text-white hover:text-fi-yellow transition-all"
                        >
                            {trans("page.quiz.topHeader")}
                        </StyledNavLink>
                        <StyledNavLink
                            to="/apningstider"
                            className="text-2xl uppercase font-bold leading-6 text-white hover:text-fi-yellow transition-all"
                        >
                            {trans("page.openHours.topHeader")}
                        </StyledNavLink>
                        <StyledNavLink
                            to="/contact"
                            className="text-2xl uppercase font-bold leading-6 text-white hover:text-fi-yellow transition-all"
                        >
                            {trans("page.contactPage.topHeader")}
                        </StyledNavLink>
                        <StyledNavLink
                            to="/about"
                            className="text-2xl uppercase font-bold leading-6 text-white hover:text-fi-yellow transition-all"
                        >
                            {trans("page.aboutPage.topHeader")}
                        </StyledNavLink>
                    </Popover.Group>
                </div>
            </nav>
            <Dialog
                as="div"
                className="lg:hidden"
                open={mobileMenuOpen}
                onClose={setMobileMenuOpen}
            >
                <div className="fixed inset-0 z-10 " />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-fi-green px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <Link to="/" onClick={() => setMobileMenuOpen(false)}>
                            <span className="sr-only">Your Company</span>
                            <img
                                className="h-28 w-auto"
                                src={require("../../assets/logo.png")}
                                alt="logo"
                            />
                        </Link>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-white"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon
                                className="h-10 w-10 hover:text-fi-yellow"
                                aria-hidden="true"
                            />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <StyledNavLink
                                    to="/program"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-fi-yellow transition-all uppercase"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    {trans("page.program.topHeader")}
                                </StyledNavLink>
                                {onlineBookingUrl && (
                                    <StyledNavLink
                                        to={onlineBookingUrl}
                                        target="_blank"
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-fi-yellow transition-all uppercase"
                                        onClick={() => setMobileMenuOpen(false)}
                                    >
                                        {trans("page.bookTable.topHeader")}
                                    </StyledNavLink>
                                )}
                                <StyledNavLink
                                    to="/quiz"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-fi-yellow transition-all uppercase"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    {trans("page.quiz.topHeader")}
                                </StyledNavLink>
                                <StyledNavLink
                                    to="/apningstider"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-fi-yellow transition-all uppercase"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    {trans("page.openHours.topHeader")}
                                </StyledNavLink>
                                <StyledNavLink
                                    to="/contact"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-fi-yellow transition-all uppercase"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    {trans("page.contactPage.topHeader")}
                                </StyledNavLink>
                                <StyledNavLink
                                    to="/about"
                                    onClick={() => setMobileMenuOpen(false)}
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-fi-yellow transition-all uppercase"
                                >
                                    {trans("page.aboutPage.topHeader")}
                                </StyledNavLink>
                            </div>
                        </div>
                        <LangSwitch styles="flex gap-4 mt-8" />
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    );
};

export default Header;
