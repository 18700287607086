import Checkbox from "../Form/Checkbox";
import { Box } from "@mui/material";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { IGPTableSelection } from "./Table";
import { IFormChangeResponse } from "../../ui/Form/Form";

interface ITableSelectorHead {
    id: string;
    rows?: any;
    selection: IGPTableSelection;
    selectionActions: ReactNode;
    tableUpdate: Function;
}

function TableSelectorHead({
    id,
    rows,
    selection,
    selectionActions,
    tableUpdate,
}: ITableSelectorHead) {
    const [actionWidth, setActionWidth] = useState<string | number>("auto");
    const { checked, indeterminate } = useMemo(() => {
        const selectedLength = selection.selected.length;

        return {
            checked: selectedLength > 0,
            indeterminate: selectedLength > 0 && selectedLength !== rows.length,
        };
    }, [rows, selection.selected]);

    useEffect(() => {
        const onResize = () => {
            const tableRef = id ? document.getElementById(id) : "";
            if (!!tableRef) {
                setActionWidth(tableRef.offsetWidth - 42);
            }
        };

        if (checked) {
            window.addEventListener("resize", onResize);
            onResize();
        }

        return () => {
            window.removeEventListener("resize", onResize);
            setActionWidth("auto");
        };
    }, [checked, id]);

    const handleChange = ({ value }: IFormChangeResponse) => {
        let items = [];
        if (value) {
            items = rows.map((item: any) => item[selection.id]);
        }

        tableUpdate("selection", items);
    };

    return (
        <>
            <Checkbox
                id={`${id}-global-row-selection`}
                onChange={handleChange}
                checked={checked}
                formControlLabelProps={{
                    sx: {
                        m: 0,
                    },
                }}
                indeterminate={indeterminate}
                sx={{ py: 0 }}
            />
            {checked && (
                <Box
                    sx={{
                        alignItems: "center",
                        backgroundColor: "#FFF",
                        display: "flex",
                        height: "100%",
                        position: "absolute",
                        left: 42,
                        px: 2,
                        top: 0,
                        width: actionWidth,
                    }}
                >
                    {selectionActions}
                </Box>
            )}
        </>
    );
}

TableSelectorHead.defaultProps = {};

export default TableSelectorHead;
