import { Box, Drawer, Icon, Stack } from "@mui/material";
import { DrawerProps } from "@mui/material";
import PropTypes from "prop-types";
import LoadingButton from "../Button/LoadingButton";

interface IGPUnsave extends Omit<DrawerProps, "onSubmit"> {
    loading: boolean;
    onSubmit: (event: any, force?: boolean) => void;
    saveText?: string;
}

function GPUnsave({ loading, onSubmit, saveText }: IGPUnsave) {
    return (
        <Drawer
            anchor="top"
            open
            variant="permanent"
            sx={{
                "& .MuiDrawer-paper": {
                    padding: (theme) => theme.spacing(0, 2),
                    zIndex: (theme) => theme.zIndex.drawer + 2,
                },
            }}
        >
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                sx={{ height: 64 }}
            >
                <Box flex={1}></Box>
                <LoadingButton
                    loading={loading}
                    onClick={onSubmit}
                    startIcon={<Icon>save</Icon>}
                    type="submit"
                >
                    {saveText ? saveText : "Save"}
                </LoadingButton>
            </Stack>
        </Drawer>
    );
}

GPUnsave.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

GPUnsave.defaultProps = {};

export default GPUnsave;
