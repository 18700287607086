import { Box, CircularProgress } from "@mui/material";
import { GPUIColor } from "../../ui/types";

interface ILoader {
    color?: GPUIColor;
    customColor?: string;
    background?: string;
    variant?: "global" | "content";
}

function Loader({ background, color, customColor, variant }: ILoader) {
    let position = "fixed";
    if (variant === "content") {
        position = "absolute";
    }
    const bgColor = background ? background : "rgba(255,255,255,1)";
    return (
        <Box
            sx={{
                alignItems: "center",
                backgroundColor: bgColor,
                display: "flex",
                height: "100%",
                justifyContent: "center",
                left: 0,
                position,
                top: 0,
                width: "100%",
                zIndex: 100,
            }}
        >
            <CircularProgress
                color={color}
                {...(customColor && { sx: { color: customColor } })}
            />
        </Box>
    );
}

Loader.defaultProps = {
    color: "primary",
    variant: "global",
};

export default Loader;
