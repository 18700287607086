import { AxiosRequestConfig } from "axios";
import { request } from "../../../api/actions";
import {
    FETCH_PROGRAM_FAILED,
    FETCH_PROGRAM_REQUEST,
    FETCH_PROGRAM_SUCCESS,
    GET_PROGRAM_FAILED,
    GET_PROGRAM_REQUEST,
    GET_PROGRAM_SUCCESS,
} from "./types";

export const eventDepratmentsFetch = (config?: AxiosRequestConfig) =>
    request(
        FETCH_PROGRAM_REQUEST,
        FETCH_PROGRAM_FAILED,
        FETCH_PROGRAM_SUCCESS,
        "eventDepratmentsFetch",
        {
            config,
        }
    );

export const eventDepartmentGet = (id: number, config?: AxiosRequestConfig) =>
    request(
        GET_PROGRAM_REQUEST,
        GET_PROGRAM_FAILED,
        GET_PROGRAM_SUCCESS,
        "eventDepartmentsGet",
        {
            url: {
                id,
            },
            config,
        }
    );
