import { AxiosRequestConfig } from "axios";
import { request } from "../api/actions";
import {
    LOCALE_FAILED,
    LOCALE_REQUEST,
    LOCALE_SUCCESS,
    SETTINGS_FAILED,
    SETTINGS_REQUEST,
    SETTINGS_SUCCESS,
} from "./types";

export const localeChange = (config?: AxiosRequestConfig) => {
    return request(
        LOCALE_REQUEST,
        LOCALE_FAILED,
        LOCALE_SUCCESS,
        "changeLocale",
        {
            config,
        }
    );
};

export const settings = (config?: AxiosRequestConfig) =>
    request(SETTINGS_REQUEST, SETTINGS_FAILED, SETTINGS_SUCCESS, "settings", {
        config,
    });
