
export const TRANSLATION_REQUEST = "TRANSLATION_REQUEST";
export const TRANSLATION_FAILED = "TRANSLATION_FAILED";
export const TRANSLATION_SUCCESS = "TRANSLATION_SUCCESS";

export const SETTINGS_REQUEST = "SETTINGS_REQUEST";
export const SETTINGS_FAILED = "SETTINGS_FAILED";
export const SETTINGS_SUCCESS = "SETTINGS_SUCCESS";

export const LOCALE_FAILED = "LOCALE_FAILED";
export const LOCALE_REQUEST = "LOCALE_REQUEST";
export const LOCALE_SUCCESS = "LOCALE_SUCCESS";
