import { FC } from "react";
import {
    NavLink,
    NavLinkProps,
    useLocation,
} from "react-router-dom";

interface StyledNavLinkProps extends NavLinkProps {
    to: string;
    className?: string;
    children: React.ReactNode;
}

const StyledNavLink: FC<StyledNavLinkProps> = ({
    to,
    className,
    children,
    ...restProps
}) => {
    const { pathname } = useLocation();
    const isActive = pathname === to;

    const resolvedClassName = `${className} ${
        isActive ? "!text-fi-yellow" : ""
    }`;

    return (
        <NavLink to={to} className={resolvedClassName} {...restProps}>
            {children}
        </NavLink>
    );
};

export default StyledNavLink;
