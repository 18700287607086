import { FC } from "react";
import trans from "../../../helpers/trans";
import Section from "../../components/Section";
import { useSelector } from "react-redux";
import { Loader } from "../../../ui";
import { NavLink } from "react-router-dom";

const QuizPage: FC = () => {
    const settings = useSelector((state: any) => state.app.settings);
    if (!settings) return <Loader background="#003d2d" customColor="#fbc243" />;

    return (
        <Section>
            <div className="flex flex-col md:flex-row w-full text-xl gap-8">
                <div className="flex flex-col w-full md:w-2/3">
                    <div className="break-words font-bold uppercase text-3xl pb-4 text-fi-yellow border-b-2 border-fi-yellow ">
                        {trans("page.quiz.title")}
                    </div>
                    <div className="mt-8">
                        <img
                            className="h-auto w-full "
                            src={settings.quizMainImage}
                            alt="quiz"
                        />
                    </div>
                    <div
                        className="mt-8  flex flex-col gap-2"
                        dangerouslySetInnerHTML={{
                            __html: trans("page.quiz.text"),
                        }}
                    ></div>
                    {settings.facebookLink && (
                        <>
                            <div className="mt-8 my-2 text-xl flex flex-col gap-2">
                                {trans("page.quiz.facebookTitle")}
                            </div>

                            <NavLink
                                to={settings.facebookLink}
                                target="_blank"
                                className="break-words text-fi-yellow hover:text-fi-yellow-2 transition duration-150 ease-in-out"
                            >
                                {settings.facebookLink}
                            </NavLink>
                        </>
                    )}
                    {(settings.quizEmail || settings.quizPhone.length > 3) && (
                        <div className="mt-8 my-2 text-xl flex flex-col gap-2">
                            {trans("page.quiz.contactQuizMaster")}
                        </div>
                    )}
                    {settings.quizPhone.length > 3 && (
                        <div className="mt-1">{settings.quizPhone}</div>
                    )}
                    {settings.quizEmail && (
                        <a
                            href={`mailto:${settings.quizEmail}`}
                            className="break-words mt-1 text-fi-yellow hover:text-fi-yellow-2 transition duration-150 ease-in-out"
                        >
                            {settings.quizEmail}
                        </a>
                    )}
                </div>
                <div className="flex flex-col  w-full md:w-1/3">
                    <div className="mt-8 pt-4">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="42"
                            height="42"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fill="currentColor"
                                d="M9 6.5a2.5 2.5 0 1 0-1.174 2.12a8.802 8.802 0 0 1-.952 2.764c-.649 1.18-1.476 2.011-2.228 2.762a.5.5 0 0 0 .708.708l.011-.012c.747-.747 1.664-1.664 2.386-2.976C8.48 10.538 9 8.83 9 6.5Zm5.826 2.12A2.5 2.5 0 1 1 16 6.5c0 2.33-.52 4.038-1.25 5.366c-.721 1.312-1.638 2.23-2.384 2.976l-.012.012a.5.5 0 0 1-.708-.708c.752-.751 1.579-1.581 2.228-2.762a8.8 8.8 0 0 0 .952-2.765Z"
                            />
                        </svg>

                        <p>{trans("page.quiz.quote")}</p>
                        <p className="mt-4 font-bold text-2xl">
                            {trans("page.quiz.quoteTitle")}
                        </p>
                    </div>
                    <div className="mt-8">
                        <img
                            className="h-auto w-full "
                            src={settings.quizImage}
                            alt="quiz"
                        />
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default QuizPage;
