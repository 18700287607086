import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { Link, NavLink, useParams } from "react-router-dom";
import useRequest from "../../../api/useRequest";
import trans from "../../../helpers/trans";
import Section from "../../components/Section";
import { eventDepartmentGet } from "./actions";
import { Loader } from "../../../ui";

const ProgramDetailsPage = () => {
    const { id }: any = useParams();
    const { currentData, isLoading, isPending } = useRequest(
        eventDepartmentGet(id, {
            params: {},
        })
    );

    if (isLoading || isPending) return <Loader background="#003d2d" customColor="#fbc243" />

    return (
        <Section>
            <div className="mb-8 flex flex-col gap-4">
                <Link
                    to="/program"
                    className="underline flex h-5 gap-1 items-center hover:text-fi-yellow transition-all"
                >
                    <ArrowLeftIcon className="h-5 w-5 " aria-hidden="true" />
                    {trans("page.program.backLink")}
                </Link>
                <div className="flex flex-col md:flex-row md:justify-between gap-4 text-left">
                    <div>
                        <div className="font-bold uppercase text-3xl text-fi-yellow">
                            {currentData.data.name}
                        </div>
                        <div className="flex flex-col">
                            <p>
                                {moment(currentData.data.date).format(
                                    "DD/MM/YYYY HH:mm"
                                )}
                            </p>
                            <p>{currentData.data.event_department_name}</p>
                        </div>
                    </div>
                    <div className="uppercase text-xl text-fi-yellow">
                        {currentData.data.video_url && (
                            <NavLink
                                to={currentData.data.video_url}
                                target="_blank"
                            >
                                <button className="shadow-lg hover:shadow-2xl text-black bg-fi-yellow hover:bg-fil-yellow-1 focus:bg-fi-yellow-2 px-5 py-2.5 text-center rounded-full transition duration-150 ease-in-out">
                                    {trans("page.program.videoButton")}
                                </button>
                            </NavLink>
                        )}
                    </div>
                </div>
                <div className="flex flex-col md:flex-row gap-8 w-full">
                    <div className="md:w-1/2">
                        {" "}
                        <img
                            className="m-auto w-auto md:h-auto"
                            src={currentData.data.image}
                            alt="event"
                        />
                    </div>
                    <div className="md:w-1/2">
                        {currentData.data.description}
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default ProgramDetailsPage;
