import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import { IGPTablePagination } from "./Table";
interface IGPTablePaginationProps {
    pagination: IGPTablePagination;
    rows: number;
    tableUpdate?: Function;
}

function GPTablePagination({
    pagination,
    rows,
    tableUpdate,
}: IGPTablePaginationProps) {
    if (rows === 0 || !tableUpdate) {
        return null;
    }

    const nextPage = () => {
        if (!pagination?.isLastPage) {
            tableUpdate("page", pagination.page + 1);
        }
    };

    const prevPage = () => {
        if (pagination.page > 1) {
            tableUpdate("page", pagination.page - 1);
        }
    };

    return (
        <Box
            sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
                mt: 1,
            }}
        >
            <Typography>
                <strong>{pagination.total}</strong>{" "}
                {pagination.total && pagination.total > 1
                    ? "Results"
                    : "Result"}
            </Typography>

            <Box>
                <Button
                    color="gray2"
                    disabled={pagination.page === 1}
                    onClick={prevPage}
                    size="small"
                    sx={{ mr: 1 }}
                    variant="outlined"
                >
                    Previous
                </Button>
                <Button
                    color="gray2"
                    disabled={pagination.isLastPage}
                    onClick={nextPage}
                    size="small"
                    variant="outlined"
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
}

GPTablePagination.propTypes = {
    tableUpdate: PropTypes.func,
};

GPTablePagination.defaultProps = {
    tableUpdate: () => null,
};

export default GPTablePagination;
