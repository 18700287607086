import { AxiosRequestConfig } from "axios";
import { publicApi } from "../apiConnections";

const requests = {
    locale: (config: AxiosRequestConfig) => {
        return publicApi.get("/translate", config);
    },
    settings: (config: AxiosRequestConfig) => publicApi.get("/settings", config),
};

export default requests;
