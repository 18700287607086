import { FC } from "react";

interface ISection {
    children: any;
}
const Section: FC<ISection> = ({ children }) => {
    return (
        <section className="flex flex-col lg:flex-row w-full lg:px-8 px-6 tracking-tighter py-16 gap-8 max-w-7xl mx-auto ">
            {children}
        </section>
    );
};

export default Section;
