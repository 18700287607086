import { FC } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";

interface IAppTemplate {
    children: any;
}

const AppTemplate: FC<IAppTemplate> = ({ children }) => {
    const location = useLocation();
    const isHero =
        location.pathname === "/" || location.pathname === "/contact";

    return (
        <div className="flex flex-col min-h-screen font-roboto bg-fi-green">
            <Header />
            <main
                className={`${
                    isHero ? "mt-36" : "p-2 lg:px-8 mt-36 mb-10 max-w-7xl"
                } text-white mx-auto w-full flex  items-center justify-between`}
            >
                {children}
            </main>
            <Footer />
        </div>
    );
};

export default AppTemplate;
