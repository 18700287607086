import { ME_SUCCESS, MY_APP_SETTINGS_SUCCESS } from "./types";
import { AnyAction } from "redux";

function general(
    state = {
        data: {},
        permissions: [],
    },
    action: AnyAction
) {
    switch (action.type) {
        case ME_SUCCESS:
            return {
                ...state,
                data: action.response.data.data,
            };

        case MY_APP_SETTINGS_SUCCESS:
            return {
                ...state,
                permissions: action.response.data.data.permissions,
            };

        default:
            return state;
    }
}

export default general;
