import api from "./api";
import { call, put, take, takeLatest } from "redux-saga/effects";
import {
    ADMIN_LOADED,
    ADMIN_TRANSLATE_REQUEST,
    ADMIN_UPDATE_SETTINGS_REQUEST,
    ADMIN_UPDATE_TRANSLATE_REQUEST,
    INIT_ADMIN,
} from "./types";
import queryString from "query-string";
import setAuthorizationHeader from "../../helpers/setAuthorizationHeader";
import pathToAuth from "../../helpers/pathToAuth";
import {
    me as meRequest,
    myAppSettings as myAppSettingsRequest,
} from "../Me/actions";
import { ME_SUCCESS,
    //  MY_APP_SETTINGS_SUCCESS
     } from "../Me/types";
import { IOrganisation } from "../Me/interfaces";
import { AnyAction } from "redux";
import { request } from "../../api/apiSaga";
import { snackbarShow, adminTranslationFetch } from "./actions";
import { settings } from "../actions";

interface IApplication {
    id: number;
    key: string;
    name: string;
}

interface IParams {
    uuid?: string;
    redirect_url?: string;
}

interface IMe {
    response: {
        data: {
            data: {
                applications: IApplication[];
                organisations: IOrganisation[];
            };
        };
    };
}

// interface IMyAppSettings {
//     response: {
//         data: {
//             data: {
//                 permissions: Array<string>;
//                 role: string;
//             };
//         };
//     };
// }

function* initAdmin() {
    const params: IParams = queryString.parse(window.location.search);

    if (!!params.uuid) {
        setAuthorizationHeader(params.uuid);
        localStorage.setItem("uuid", params.uuid);

        if (!params.redirect_url || params.redirect_url === "/") {
            window.location.href = "/admin";
        } else {
            window.location.href = params.redirect_url;
        }

        return;
    }

    if (window.location.href.includes("admin")) {
        //check if is stored uuid
        const uuid = localStorage.getItem("uuid");
        if (uuid === null) {
            window.location.href = pathToAuth();
            return;
        }
        setAuthorizationHeader(uuid);
        yield put(meRequest());

        const me: IMe = yield take(ME_SUCCESS);

        const application = me.response.data.data.applications.find(
            (application: IApplication) =>
                application.key === process.env.REACT_APP_APPLICATION_UID
        );

        if (!application) {
            window.location.href = pathToAuth();
            return;
        }
        yield put(myAppSettingsRequest());
        yield put(adminTranslationFetch())

        // const myAppSettings: IMyAppSettings = yield take(
        //     MY_APP_SETTINGS_SUCCESS
        // );
        localStorage.removeItem("reloadedApp");
        yield put({
            type: ADMIN_LOADED,
        });
    }
}

function* fetchTrans(action: AnyAction): any {
    yield call(request, api.fetchTrans, action);
}

function* updateTrans(action: AnyAction): any {
    const response = yield call(request, api.updateTrans, action, "put");

    if (response.status === 204) {
        yield put(adminTranslationFetch())
        yield put(
            snackbarShow({
                content: "Translation updated",
            })
        );
    }
}

function* updateSettings(action: AnyAction): any {
    const response = yield call(request, api.updateSettings, action, "put");
    if (response.status === 204) {
        yield put(settings())
        yield put(
            snackbarShow({
                content: "Settings updated",
            })
        );
    }
}

export const adminSagas = [
    takeLatest(INIT_ADMIN, initAdmin),
    takeLatest(ADMIN_UPDATE_TRANSLATE_REQUEST, updateTrans),
    takeLatest(ADMIN_TRANSLATE_REQUEST, fetchTrans),
    takeLatest(ADMIN_UPDATE_SETTINGS_REQUEST, updateSettings),
];
