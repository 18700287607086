import { APP_INIT } from "./types";
import { all, put } from "redux-saga/effects";

import { adminSagas } from "./App/Admin/saga";
import { meSagas } from "./App/Me/saga";
import { appSagas } from "./App/saga";

export default function* rootSaga() {
    yield all([...adminSagas, ...meSagas, ...appSagas]);
    yield put({ type: APP_INIT });
}
