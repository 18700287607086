import { accounts, api } from "../apiConnections"

const setAuthorizationHeader = (token = "") => {
	if(token) {
		accounts.defaults.headers.common['Authorization'] = `Bearer ${token}`;
		api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	} else {
		delete accounts.defaults.headers.common['Authorization'];
		delete api.defaults.headers.common['Authorization'];
	}
};

export default setAuthorizationHeader;