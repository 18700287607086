import types from "./types";
import { AnyAction } from "redux";
import { request } from "../../api/apiSaga";
import { call, takeLatest } from "@redux-saga/core/effects";
import { AxiosResponse } from "axios";
import api from "./api";

function* logout(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.logout,
        action,
        "post"
    );

    if (response.status < 300) {
        localStorage.removeItem("uuid");
        window.location.href = `/`;
    }
}

function* me(action: AnyAction) {
    yield call(request, api.me, action);
}

export function* myAppSettings(action: AnyAction) {
    yield call(request, api.myAppSettings, action);
}

export const meSagas = [
    takeLatest(types.ME_REQUEST, me),
    takeLatest(types.MY_APP_SETTINGS_REQUEST, myAppSettings),
    takeLatest(types.LOGOUT_REQUEST, logout),
];
