import { AnyAction } from "redux";
import { call, takeLatest } from "redux-saga/effects";
import { request } from "../../../api/apiSaga";
import api from "./api";
import { FETCH_PROGRAM_REQUEST, GET_PROGRAM_REQUEST } from "./types";

function* fetch(action: AnyAction): any {
    yield call(request, api.fetch, action);
}

function* get(action: AnyAction): any {
    yield call(request, api.get, action);
}

export const eventsSagas = [
    takeLatest(FETCH_PROGRAM_REQUEST, fetch),
    takeLatest(GET_PROGRAM_REQUEST, get),
];
