import { composeWithDevTools } from "@redux-devtools/extension";
import { applyMiddleware, combineReducers, createStore, Store } from "redux";
import createSagaMiddleware from "redux-saga";
import apiMiddleware from "./api/apiMiddleware";
import dynamicMiddlewares from "./helpers/dynamicMiddlewares";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

interface IReducer {
    add: (key: string, reducer: any) => void;
    getReducerMap: () => void;
    reduce: (state: any, action: never) => void;
    remove: (key: string) => void;
}
interface IStore extends Store {
    injectSaga?: (key: string, saga: any) => void;
    liftedStore?: any;
    reducerManager?: IReducer;
}
const sagaMiddleware = createSagaMiddleware();
const middleware = applyMiddleware(
    apiMiddleware,
    dynamicMiddlewares,
    sagaMiddleware
);

const reducerManager = createReducerManager(rootReducer);

const store: IStore = createStore(
    reducerManager.reduce,
    composeWithDevTools(middleware)
);

store.reducerManager = reducerManager;
store.injectSaga = createSagaInjector(sagaMiddleware.run, rootSaga);

function createReducerManager(initialReducers: any): IReducer {
    // Create an object which maps keys to reducers
    const reducers = { ...initialReducers };

    // Create the initial combinedReducer
    let combinedReducer = combineReducers(reducers);

    // An array which is used to delete state keys when reducers are removed
    let keysToRemove: Array<string> = [];

    return {
        getReducerMap: () => reducers,

        // The root reducer function exposed by this object
        // This will be passed to the store
        reduce: (state, action) => {
            // If any reducers have been removed, clean up their state first
            if (keysToRemove.length > 0) {
                state = { ...state };
                for (let key of keysToRemove) {
                    delete state[key];
                }
                keysToRemove = [];
            }

            // Delegate to the combined reducer
            return combinedReducer(state, action);
        },

        // Adds a new reducer with the specified key
        add: (key, reducer) => {
            if (!key || reducers[key]) {
                return;
            }

            // Add the reducer to the reducer mapping
            reducers[key] = reducer;

            // Generate a new combined reducer
            combinedReducer = combineReducers(reducers);
            store.dispatch({ type: "REDUCER_ADDED", id: key });
        },

        // Removes a reducer with the specified key
        remove: (key) => {
            if (!key || !reducers[key]) {
                return;
            }

            // Remove it from the reducer mapping
            delete reducers[key];

            // Add the key to the list of keys to clean up
            keysToRemove.push(key);

            // Generate a new combined reducer
            combinedReducer = combineReducers(reducers);
        },
    };
}

function createSagaInjector(runSaga: any, rootSaga: any) {
    // Create a dictionary to keep track of injected sagas
    const injectedSagas = new Map();

    const isInjected = (key: string) => injectedSagas.has(key);

    const injectSaga = (key: string, saga: any) => {
        // We won't run saga if it is already injected
        if (isInjected(key)) return;

        // Sagas return task when they executed, which can be used
        // to cancel them
        const task = runSaga(saga);

        // Save the task if we want to cancel it in the future
        injectedSagas.set(key, task);
    };

    // Inject the root saga as it a staticlly loaded file,
    injectSaga("root", rootSaga);

    return injectSaga;
}

export default store;
