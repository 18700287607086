import get from "lodash/get";
import translate from "../translate.json";
const translateJSON = translate;

const trans = (text: string, tags?: { [key: string]: any }): string => {
    let translate = get(window.translate, text);

    if (translate === undefined || typeof translate !== "string") {
        return text;
    }

    for (var i in tags) {
        translate = translate.replace(new RegExp(`:${i}`, "g"), tags[i]);
    }

    return translate;
};

export const transObject = (
    text: string,
    tags: { [key: string]: any }
): string[] => {
    let translate = get(translateJSON, text);

    if (translate === undefined || typeof translate !== "string") {
        return [text];
    }

    let t = [translate];

    for (let tag of Object.keys(tags)) {
        t = setTags(t, tag, tags[tag]);
    }

    return t;
};

function setTags(arr: string[], tag: string, value: any): string[] {
    let nt: string[] = [];

    for (let i in arr) {
        if (typeof arr[i] === "string") {
            if (arr[i].indexOf(`:${tag}`) !== -1) {
                const temp = arr[i].split(`:${tag}`);

                const ret = [];
                const len = temp.length;

                for (let t = 0; t < len; ++t) {
                    ret.push(temp[t]);

                    if (t < len - 1) {
                        ret.push(value);
                    }
                }

                nt = [...nt, ...ret];
            } else {
                nt.push(arr[i]);
            }
        } else {
            nt.push(arr[i]);
        }
    }

    return nt;
}

export default trans;
