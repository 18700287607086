import { FC, useContext } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import FormContext from "./FormContext";
import dayjs from "dayjs";
import { getValue } from "./helpers";

interface ITimePickerValue {
    id: string;
    onChange?: Function;
    label?: string;
    value?: any;
}

const TimePickerValue: FC<ITimePickerValue> = ({
    id,
    onChange,
    label,
    value,
}) => {

    const context = useContext(FormContext);
    const onChangeValue = (value: any) => {
        if (!!onChange) {
            onChange({ id, value }, context);
        } else {
            context?.onChange({ id, value });
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker", "TimePicker"]}>
                <TimePicker
                    ampm={false}
                    format="HH:mm"
                    label={label}
                    value={dayjs(getValue(id, context, value))}
                    onChange={onChangeValue}
                    sx={{
                        ".Mui-error": {
                            color: "inherit !important",
                        },
                        ".Mui-error .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 0.23) !important",
                        },
                        ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#36845B !important",
                        },
                        ".MuiInputBase-input.MuiOutlinedInput-input": {
                            padding: "8.5px 14px"
                        },
                        ".MuiFormLabel-root.MuiInputLabel-root[data-shrink='false']": {
                            top: "-7px"
                        }
                    }}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
};

export default TimePickerValue;
