import { AxiosRequestConfig } from "axios";
import { accounts, api } from "../../apiConnections";

const requests = {
    logout: (data: object, config: AxiosRequestConfig) =>
        accounts.post(`/api/logout`, data, config),
    me: (config: AxiosRequestConfig) => accounts.get(`/api/user/me`, config),
    myAppSettings: (config: AxiosRequestConfig) => api.get(`/user/me`, config),
    myAvaliableApplications: (config: AxiosRequestConfig) =>
        api.get("/user/me/data/applications", config),
};

export default requests;
