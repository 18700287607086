import { AxiosRequestConfig } from "axios";
import { publicApi } from "../../../apiConnections";
import RequestUrlId from "../../../types/RequestUrlId";

const baseUrl = "/events";

const requests = {
    fetch: (config: AxiosRequestConfig) => publicApi.get(baseUrl, config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        publicApi.get(`${baseUrl}/${url.id}`, config),
};

export default requests;
