import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import ScrollToTop from "./App/components/ScrollToTop";
import AppTemplate from "./App/templates/AppTemplate";
import "./index.css";
import AppRouting from "./rootRouting";
import { ThemeProvider } from "@mui/material";
import AdminInit from "./App/Admin/components/AdminInit";
import CookieConsent from "./App/components/CookieConsent";
import { Loader } from "./ui";
import theme from "./ui/theme";

function App() {
    const globalLoader = useSelector((state: any) => state.app.globalLoader);
    const loaded = useSelector((state: any) => state.app.loaded);

    if (!loaded) return <Loader background="#003d2d" customColor="#fbc243" />;

    return (
        <Routes>
            <Route
                path="/admin/*"
                element={
                    <ThemeProvider theme={theme}>
                        <AdminInit />
                    </ThemeProvider>
                }
            />
            <Route
                path="/*"
                element={
                    <>
                        <CookieConsent />
                        <AppTemplate>
                            <ScrollToTop />
                            {globalLoader ? (
                                <Loader
                                    background="#003d2d"
                                    customColor="#fbc243"
                                />
                            ) : (
                                <AppRouting />
                            )}
                        </AppTemplate>
                    </>
                }
            />
        </Routes>
    );
}

export default App;
