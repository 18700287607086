import { FC } from "react";
import { useSelector } from "react-redux";
import loadable from "@loadable/component";
import AdminLoader from "./AdminLoader";

const Admin = loadable(() => import(".."));

const AdminInit: FC = () => {
    const loaded = useSelector((state: any) => state.app.adminLoaded);

    if (!loaded) {
        return <AdminLoader />;
    }

    return <Admin />;
};

export default AdminInit;
