import { useEffect, useRef, useState, FC } from "react";
import { Icon, IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { styled } from "@mui/material/styles";

import usePrevious from "../../hooks/usePrevious";

const StyledOutlinedInput = styled(OutlinedInput)({
    backgroundColor: "#FFF",
    paddingRight: 0,
});

interface ISearch {
    onChange: Function;
    value: string;
}

const Search: FC<ISearch> = ({ onChange, value: defaultValue }) => {
    const inputEl = useRef<HTMLInputElement | null>(null);

    const [searchOpen, setSearchOpen] = useState(defaultValue.length > 0);
    const [value, setValue] = useState(defaultValue);
    const prevValue = usePrevious(value);

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;
        // clearTimeout(timeout);

        if (prevValue === undefined || prevValue === value) {
            return;
        }

        if (value.length > 0) {
            timeout = setTimeout(() => {
                onChange(value);
            }, 500);
        } else {
            onChange(value);
        }

        return () => clearTimeout(timeout);
    }, [value, prevValue, onChange]);

    useEffect(() => {
        if (defaultValue !== value) {
            if (defaultValue.length > 0) {
                setSearchOpen(true);
                setValue(defaultValue);

                if (inputEl.current !== null) {
                    inputEl.current.focus();
                }
            } else {
                setSearchOpen(false);
                setValue("");
            }
        }

        // eslint-disable-next-line
    }, [defaultValue]);

    const onBlur = (event: React.FocusEvent<HTMLElement>) => {
        if (value.length === 0 && defaultValue.length === 0) {
            setSearchOpen(false);
        }
    };

    const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const onClickClear = (event: React.MouseEvent<HTMLElement>) => {
        setValue("");
        if (inputEl.current !== null) {
            inputEl.current.focus();
        }
    };

    if (searchOpen) {
        return (
            <StyledOutlinedInput
                autoFocus={true}
                endAdornment={
                    defaultValue.length > 0 && (
                        <InputAdornment position="end">
                            <IconButton onClick={onClickClear}>
                                <Icon>close</Icon>
                            </IconButton>
                        </InputAdornment>
                    )
                }
                onBlur={onBlur}
                onChange={onChangeValue}
                margin="dense"
                placeholder="Search"
                inputRef={inputEl}
                size="small"
                startAdornment={
                    <InputAdornment position="start">
                        <Icon sx={{ color: "grey.400" }}>search</Icon>
                    </InputAdornment>
                }
                sx={{
                    width: 200,
                }}
                value={value}
                // variant="outlined"
            />
        );
    }

    return (
        <IconButton onClick={(event) => setSearchOpen(true)}>
            <Icon>search</Icon>
        </IconButton>
    );
};

export default Search;
