export const INIT_ADMIN = "INIT_ADMIN";

export const ADMIN_LOADED = "ADMIN_LOADED";
export const UPDATE_SETTINGS_FAILED = "SETTINGS/UPDATE_FAILED";
export const UPDATE_SETTINGS_REQUEST = "SETTINGS/UPDATE_REQUEST";
export const UPDATE_SETTINGS_SUCCESS = "SETTINGS/UPDATE_SUCCESS";

export const ADMIN_SETTINGS_FAILED = "ADMIN_SETTINGS/FETCH_FAILED";
export const ADMIN_SETTINGS_REQUEST = "ADMIN_SETTINGS/FETCH_REQUEST";
export const ADMIN_SETTINGS_SUCCESS = "ADMIN_SETTINGS/FETCH_SUCCESS";

export const ADMIN_UPDATE_SETTINGS_FAILED = "ADMIN_SETTINGS/UPDATE_FAILED";
export const ADMIN_UPDATE_SETTINGS_REQUEST = "ADMIN_SETTINGS/UPDATE_REQUEST";
export const ADMIN_UPDATE_SETTINGS_SUCCESS = "ADMIN_SETTINGS/UPDATE_SUCCESS";

export const ADMIN_TRANSLATE_FAILED = "ADMIN_TRANSLATE/FETCH_FAILED";
export const ADMIN_TRANSLATE_REQUEST = "ADMIN_TRANSLATE/FETCH_REQUEST";
export const ADMIN_TRANSLATE_SUCCESS = "ADMIN_TRANSLATE/FETCH_SUCCESS";

export const ADMIN_UPDATE_TRANSLATE_FAILED = "ADMIN_TRANSLATE/UPDATE_FAILED";
export const ADMIN_UPDATE_TRANSLATE_REQUEST = "ADMIN_TRANSLATE/UPDATE_REQUEST";
export const ADMIN_UPDATE_TRANSLATE_SUCCESS = "ADMIN_TRANSLATE/UPDATE_SUCCESS";