import trans from "../../helpers/trans";
import { Loader } from "../../ui";

import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";

const Footer: FC = () => {
    const location = useLocation();
    const settings = useSelector((state: any) => state.app.settings);
    const lang = localStorage.getItem("lang");

    useEffect(() => {
        if (location.search.includes("footer")) {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
            });
        }
    }, [location, settings]);

    if (!settings) return <Loader background="#003d2d" customColor="#fbc243" />;

    return (
        <footer className="bg-fi-green mt-auto break-words shadow-[0_-25px_50px_-12px_rgba(0,0,0,0.25)] ">
            <div className="w-full flex flex-col justify-center md:justify-between mx-auto max-w-7xl items-center p-2 py-5 lg:px-8 tracking-tighter font-semibold uppercase text-lg text-white">
                <nav className="w-full text-center md:text-left md:justify-around gap-4 flex flex-col md:flex-row py-5 ">
                    <div className="p-3 flex justify-center">
                        {settings.facebookLink && (
                            <NavLink to={settings.facebookLink} target="_blank">
                                <svg
                                    className="w-6 h-6 text-fi-yellow hover:text-fi-yellow-2 transition duration-150 ease-in-out"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 8 19"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </NavLink>
                        )}
                    </div>
                    <div className="p-3">
                        <div className="flex flex-col gap-4">
                            <NavLink to="/program">
                                <div className="cursor-pointer hover:text-fi-yellow transition duration-150 ease-in-out">
                                    {trans("page.program.footerHeader")}
                                </div>
                            </NavLink>
                            {settings.onlineBookingUrl && (
                                <NavLink
                                    to={`${settings.onlineBookingUrl}?locale=${lang}`}
                                    target="_blank"
                                >
                                    <div className="cursor-pointer hover:text-fi-yellow transition duration-150 ease-in-out">
                                        {trans("page.bookTable.footerHeader")}
                                    </div>
                                </NavLink>
                            )}
                            <NavLink to="/quiz">
                                <div className="cursor-pointer hover:text-fi-yellow transition duration-150 ease-in-out">
                                    {trans("page.quiz.footerHeader")}
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="p-3">
                        <div className="flex flex-col gap-4">
                            <NavLink to="/apningstider">
                                <div className="cursor-pointer hover:text-fi-yellow transition duration-150 ease-in-out">
                                    {trans("page.openHours.footerHeader")}
                                </div>
                            </NavLink>
                            <NavLink to="/contact">
                                <div className="cursor-pointer hover:text-fi-yellow transition duration-150 ease-in-out">
                                    {trans("page.contactPage.footerHeader")}
                                </div>
                            </NavLink>
                            <NavLink to="/about">
                                <div className="cursor-pointer hover:text-fi-yellow transition duration-150 ease-in-out">
                                    {trans("page.aboutPage.footerHeader")}
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </nav>
                <div className="justify-content-between">
                    <Link to="/">
                        <span className="sr-only">Finnegan's</span>
                        <img
                            className="h-36 w-auto"
                            src={require("../../assets/BannerLogo.png")}
                            alt="logo"
                        />
                    </Link>
                </div>
                <div className="tracking-normal mb-4 font-normal italic normal-case text-fi-yellow">
                    {trans("page.footer.bannerText")}
                </div>
            </div>
        </footer>
    );
};

export default Footer;
