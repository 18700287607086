import { FC } from "react";
import useRequest from "../../api/useRequest";
import { localeChange } from "../actions";

interface ILangSwitch {
    styles?: string;
}

const LangSwitch: FC<ILangSwitch> = ({ styles }) => {
    const { request } = useRequest();

    const handleLangSwitch = (lang: string) => {
        request(localeChange({ params: { locale: lang } }));
    };

    return (
        <div className={`${styles}`}>
            <div onClick={() => handleLangSwitch("en")}>
                <img
                    className={`w-8 h-auto hover:brightness-100 ${
                        document.documentElement.lang === "en"
                            ? "brightness-100"
                            : "brightness-75"
                    } transition-all cursor-pointer`}
                    src={require("../../assets/en-flag.png")}
                    alt="england flag"
                />
            </div>
            <div onClick={() => handleLangSwitch("nb")}>
                <img
                    className={`w-8 h-auto hover:brightness-100 transition-all cursor-pointer ${
                        document.documentElement.lang === "nb"
                            ? "brightness-100"
                            : "brightness-75"
                    }`}
                    src={require("../../assets/no-flag.png")}
                    alt="norway flag"
                />
            </div>
        </div>
    );
};

export default LangSwitch;
