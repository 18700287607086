import api from "./api/apiReducer";
import app from "./App/Admin/reducer";
import me from "./App/Me/reducer";

const rootReducer = {
    api,
    app,
    me,
};

export default rootReducer;
