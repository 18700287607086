import Section from "../../components/Section";
import ProgramTable from "./tables/ProgramTable";

const ProgramPage = () => {
    return (
        <Section>
            <ProgramTable />
        </Section>
    );
};

export default ProgramPage;
