import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return null;
};

export default ScrollToTop;
