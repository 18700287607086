import { useRoutes } from "react-router-dom";
import IndexPage from "./App/pages/IndexPage";
import NotFound from "./App/pages/NotFound";
import ProgramPage from "./App/pages/Program";
import ProgramDetailsPage from "./App/pages/Program/ProgramDetailsPage";
import ContactPage from "./App/pages/Contact";
import ApningstiderPage from "./App/pages/Apningstider";
import ApningstiderDetailsPage from "./App/pages/Apningstider/ApningstiderDetailsPage";
import AboutPage from "./App/pages/About";
import QuizPage from "./App/pages/Quiz";

const AppRouting = () => {
    return useRoutes([
        { path: "/", element: <IndexPage /> },
        { path: "/program", element: <ProgramPage /> },
        { path: "/program/:id/more", element: <ProgramDetailsPage /> },
        { path: "/contact", element: <ContactPage /> },
        { path: "/apningstider", element: <ApningstiderPage /> },
        {
            path: "/apningstider/:id/details",
            element: <ApningstiderDetailsPage />,
        },
        { path: "/about", element: <AboutPage /> },
        { path: "/quiz", element: <QuizPage /> },
        { path: "*", element: <NotFound /> },
    ]);
};

export default AppRouting;
