import { AxiosRequestConfig } from "axios";
import { api } from "../../apiConnections";

const requests = {
    fetchTrans: (config: AxiosRequestConfig) => api.get("/translate", config),
    updateTrans: (data: object, config: AxiosRequestConfig) =>
        api.put("/translate", data, config),
    fetchSettings: (config: AxiosRequestConfig) => api.get("/settings", config),
    updateSettings: (data: object, config: AxiosRequestConfig) =>
        api.put("/settings", data, config),
};

export default requests;
