import { FC } from "react";
import trans from "../../../helpers/trans";
import { useSelector } from "react-redux";
import { Loader } from "../../../ui";

const ContactPage: FC = () => {
    const settings = useSelector((state: any) => state.app.settings);
    if (!settings) return <Loader background="#003d2d" customColor="#fbc243" />;

    return (
        <div
            style={{ backgroundImage: `url(${settings.contactImage})` }}
            className="w-full h-[calc(100vh-144px)] bg-cover bg-center flex justify-center items-center tracking-tighter"
        >
            <div className="absolute inset-0 bg-black opacity-60 h-[calc(100vh-144px)] top-[144px]"></div>
            <div className="flex justify-center items-center flex-col flex-wrap z-1 gap-8">
                <div className="flex flex-col text-2xl text-center gap-2 sm:gap-3">
                    <p className="text-4xl uppercase font-bold">
                        {trans("page.contactPage.title")}
                    </p>
                    <p className="font-bold mt-8">
                        {trans("page.contactPage.name")}
                    </p>
                    <p className="">{trans("page.contactPage.address")}</p>
                    <p className="font-bold mt-8">
                        {trans("page.contactPage.contactTitle")}
                    </p>
                    {settings.phone?.length > 3 && <p className="">{settings.phone}</p>}
                    {settings.email && (
                        <a
                            href={`mailto:${settings.email}`}
                            className="mt-1 underline hover:text-fi-yellow-2 transition duration-150 ease-in-out"
                        >
                            {settings.email}
                        </a>
                    )}
                    {(settings.quizEmail || settings.quizPhone?.length > 3) && <p className="font-bold mt-8">
                        {trans("page.contactPage.contactQuizMaster")}
                    </p>}
                    {settings.quizPhone?.length > 3 && (
                        <p className="">{settings.quizPhone}</p>
                    )}
                    {settings.quizEmail && (
                        <a
                            href={`mailto:${settings.quizEmail}`}
                            className="mt-1 underline hover:text-fi-yellow-2 transition duration-150 ease-in-out"
                        >
                            {settings.quizEmail}
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
