// import React, { FC } from "react";
// import { Link } from "react-router-dom";
// import trans from "../../../helpers/trans";
// import Section from "../../components/Section";

// interface IApningstiderPage {}

// const departments = [
//     {
//         id: 1,
//         city: "Oslo",
//         localization: "Oslo",
//     },
//     {
//         id: 2,
//         city: "Bergen",
//         localization: "Bergen",
//     },
//     {
//         id: 3,
//         city: "Oslo",
//         localization: "Oslo",
//     },
//     {
//         id: 4,
//         city: "Bergen",
//         localization: "Bergen",
//     },
// ];

// const ApningstiderPage: FC<IApningstiderPage> = () => {
//     return (
//         <Section>
//             <div className="flex flex-col w-full">
//                 <h1 className="text-center w-full text-3xl uppercase font-bold tracking-tighter">
//                     {trans("page.openHours.topHeader")}
//                 </h1>
//                 <section className="pt-12 grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
//                     {departments.map((item) => (
//                         <div
//                             key={item.id}
//                             className="hover:scale-105 transition-all group"
//                         >
//                             <Link
//                                 to={`/apningstider/${item.id}/details`}
//                                 className="flex flex-col gap-2"
//                             >
//                                 <div className="bg-cyan-400 flex items-center justify-center h-52 ">
//                                     image
//                                 </div>
//                                 <p className="text-center font-semibold text-2xl uppercase group-hover:text-fi-yellow transition-all">
//                                     {item.localization}
//                                 </p>
//                                 <p className="text-center">{item.city}</p>
//                             </Link>
//                         </div>
//                     ))}
//                 </section>
//             </div>
//         </Section>
//     );
// };

import { FC } from "react";
import trans from "../../../helpers/trans";
import Section from "../../components/Section";
import { useSelector } from "react-redux";
import moment from "moment";

const ApningstiderDetailsPage: FC = () => {
    const settings = useSelector((state: any) =>
        JSON.parse(state.app.settings.openHours)
    );

    return (
        <Section>
            <div className="mx-auto w-full max-w-sm">
                <h1 className="text-3xl uppercase font-bold tracking-tighter mt-4">
                    {trans("page.openHours.topHeader")}
                </h1>

                <div>
                    {Object.keys(settings).map((day: string) => {
                        const isClosed = settings[day].isClosed;
                        const from = settings[day].from
                            ? moment(settings[day].from).format("HH:mm")
                            : "";
                        const to = settings[day].to
                            ? moment(settings[day].to).format("HH:mm")
                            : "";
                        const openHoursText = isClosed
                            ? trans("page.openHours.closedText")
                            : `${from} - ${to}`;

                        return (
                            <div
                                key={day}
                                className="flex border-t py-4 px-2 border-gray-200"
                            >
                                <p className="basis-1/2 uppercase font-semibold text-lg">
                                    {trans(`page.openHours.${day}`)}
                                </p>
                                <p className="basis-1/2 uppercase font-semibold text-lg">
                                    {openHoursText}
                                </p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Section>
    );
};

export default ApningstiderDetailsPage;
