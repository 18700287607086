import { FC } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { LogoIcon } from "../../../ui";

const AdminLoader: FC = () => {
    return (
        <Box
            sx={{
                alignItems: "center",
                // animation: "gradient 5s ease infinite",
                background: (theme) =>
                    `linear-gradient(160deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
                // backgroundSize: "400% 400%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                left: 0,
                position: "fixed",
                top: 0,
                width: "100%",

                "@keyframes gradient": {
                    "0%": {
                        backgroundPosition: "0% 50%",
                    },
                    "50%": {
                        backgroundPosition: "100% 50%",
                    },
                    "100%": {
                        backgroundPosition: "0% 50%",
                    },
                },
            }}
        >
            <Paper
                sx={{
                    maxWidth: 250,
                    overflow: "hidden",
                    paddingBottom: 2,
                    textAlign: "center",
                    width: "100%",
                }}
            >
                <LogoIcon
                    sx={{
                        color: (theme: any) => theme.palette.primary.main,
                        fontSize: 80,
                        margin: [2, 0, 2],
                        position: "relative",

                        animation: "beat .75s infinite alternate",
                        transformOrigin: "center",

                        "@keyframes beat": {
                            to: { transform: "scale(1.2)" },
                        },
                    }}
                />
                <Typography variant="h5">Finnegan's</Typography>
            </Paper>
        </Box>
    );
};

export default AdminLoader;
