import moment from "moment";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import useRequest from "../../../../api/useRequest";
import trans from "../../../../helpers/trans";
import { eventDepratmentsFetch } from "../actions";
import { Loader } from "../../../../ui";

const ProgramTable: FC = () => {
    const { currentData, isError, isLoading } = useRequest(
        eventDepratmentsFetch({
            params: {
                _filters: { active: true, eventInFuture: true },
                _sort: { date: "asc" },
            },
        })
    );

    if (isLoading) return <Loader background="#003d2d" customColor="#fbc243" />;

    if (isError) return null;

    return (
        <table className="border-separate border-spacing-4 w-full table-auto">
            <thead className="hidden md:table-header-group text-xl text-fi-yellow">
                <tr>
                    <th></th>
                    <th>{trans("page.program.tableEvent")}</th>
                    <th>{trans("page.program.tableDate")}</th>
                    <th>{trans("page.program.tablePlace")}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {currentData?.data.map((event: any) => {
                    return (
                        <tr
                            key={`event-row-${event.id}`}
                            className="mb-8 flex flex-col gap-2 justify-center items-center md:table-row"
                        >
                            <td className="text-center">
                                {" "}
                                <img
                                    className="m-auto h-auto w-64 md:w-36"
                                    src={event.image}
                                    alt="event"
                                />
                            </td>
                            <td className="text-center whitespace-nowrap">
                                {event.name}
                            </td>
                            <td className="text-center whitespace-nowrap">
                                {moment(event.date).format("DD/MM/YYYY HH:mm")}
                            </td>
                            <td className="text-center">
                                {event.event_department_name}
                            </td>
                            <td className="text-center">
                                {event.video_url && (
                                    <NavLink
                                        to={event.video_url}
                                        target="_blank"
                                    >
                                        <button className="shadow-lg hover:shadow-2xl text-black bg-fi-yellow hover:bg-fil-yellow-1 focus:bg-fi-yellow-2 px-5 py-2.5 text-center rounded-full transition duration-150 ease-in-out">
                                            {trans("page.program.videoButton")}
                                        </button>
                                    </NavLink>
                                )}
                                <NavLink to={`/program/${event.id}/more`}>
                                    <button className="shadow-lg hover:shadow-2xl text-black bg-fi-yellow hover:bg-fil-yellow-1 focus:bg-fi-yellow-2 px-5 py-2.5 text-center m-2 rounded-full transition duration-150 ease-in-out">
                                        {trans("page.program.moreButton")}
                                    </button>
                                </NavLink>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default ProgramTable;
