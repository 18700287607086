import { AxiosRequestConfig } from "axios";
import {
    APP_HANDLE_ERROR,
    CONFIRMATION_CONFIRMED,
    CONFIRMATION_UNCONFIRMED,
    MODAL_HIDE,
    MODAL_SHOW,
    SNACKBAR_HIDE,
    SNACKBAR_SHOW,
    SIDE_NAV_TYPE,
} from "../../types";
import { request } from "../../api/actions";
import { ADMIN_SETTINGS_FAILED, ADMIN_SETTINGS_REQUEST, ADMIN_SETTINGS_SUCCESS, ADMIN_TRANSLATE_FAILED, ADMIN_TRANSLATE_REQUEST, ADMIN_TRANSLATE_SUCCESS, ADMIN_UPDATE_SETTINGS_FAILED, ADMIN_UPDATE_SETTINGS_REQUEST, ADMIN_UPDATE_SETTINGS_SUCCESS, ADMIN_UPDATE_TRANSLATE_FAILED, ADMIN_UPDATE_TRANSLATE_REQUEST, ADMIN_UPDATE_TRANSLATE_SUCCESS } from "./types";

export const confirmationConfirmed = () => ({
    type: CONFIRMATION_CONFIRMED,
});
export const confirmationUnconfirmed = () => ({
    type: CONFIRMATION_UNCONFIRMED,
});

export const handleError = (error: any) => ({
    type: APP_HANDLE_ERROR,
    error,
});

export const modalHide = (id: string, props?: any) => ({
    type: MODAL_HIDE,
    id,
    props,
});
export const modalShow = (id: string, props?: any) => ({
    type: MODAL_SHOW,
    id,
    props,
});

export const snackbarHide = () => ({
    type: SNACKBAR_HIDE,
});

export const snackbarShow = (data: any, closeAfter = 6000, type = "alert") => {
    return {
        type: SNACKBAR_SHOW,
        data: {
            data,
            closeAfter,
            type,
        },
    };
};

export const changeSideNavType = (type: string) => ({
    type: SIDE_NAV_TYPE,
    data: type,
});

export const adminTranslationFetch = (config?: AxiosRequestConfig) =>
    request(
        ADMIN_TRANSLATE_REQUEST,
        ADMIN_TRANSLATE_FAILED,
        ADMIN_TRANSLATE_SUCCESS,
        "adminTranslationFetch",
        {
            config,
        }
    );

export const adminTranslationUpdate = (data: any, onResponse?: Function) => {
    return request(
        ADMIN_UPDATE_TRANSLATE_REQUEST,
        ADMIN_UPDATE_TRANSLATE_FAILED,
        ADMIN_UPDATE_TRANSLATE_SUCCESS,
        "adminTranslationUpdate",
        { data },
        onResponse
    );
};
export const adminSettingsFetch = (config?: AxiosRequestConfig) =>
    request(
        ADMIN_SETTINGS_REQUEST,
        ADMIN_SETTINGS_FAILED,
        ADMIN_SETTINGS_SUCCESS,
        "adminSettingsFetch",
        { config }
    );

export const adminSettingsUpdate = (data: any, onResponse?: Function) => {
    return request(
        ADMIN_UPDATE_SETTINGS_REQUEST,
        ADMIN_UPDATE_SETTINGS_FAILED,
        ADMIN_UPDATE_SETTINGS_SUCCESS,
        "adminSettingsUpdate",
        { data },
        onResponse
    );
};
