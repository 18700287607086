import { AxiosResponse } from "axios";
import { request } from "../../api/actions";
import { PermissionModel } from "./interfaces";
import types from "./types";

export const logout = () =>
    request(types.LOGOUT_REQUEST, types.LOGOUT_FAILED, types.LOGOUT_SUCCESS);

export const me = () =>
    request(types.ME_REQUEST, types.ME_FAILED, types.ME_SUCCESS);

export const myAppSettings = () =>
    request(
        types.MY_APP_SETTINGS_REQUEST,
        types.MY_APP_SETTINGS_FAILED,
        types.MY_APP_SETTINGS_SUCCESS,
        undefined,
        {},
        undefined,
        (response: AxiosResponse) => {
            return {
                ...response,
                data: {
                    ...response.data,
                    data: {
                        ...response.data.data,
                        permissions: response.data.data.permissions.map(
                            (item: PermissionModel) => item.name
                        ),
                    },
                },
            };
        }
    );
