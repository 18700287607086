import {
    APP_INIT,
    APP_LOADED,
    MODAL_HIDE,
    MODAL_SHOW,
    SNACKBAR_HIDE,
    SNACKBAR_SHOW,
    SIDE_NAV_TYPE,
} from "../../types";

import { AnyAction } from "redux";

import { rs } from "../../helpers/reducers";
import {
    ADMIN_LOADED,
    ADMIN_TRANSLATE_FAILED,
    ADMIN_TRANSLATE_REQUEST,
    ADMIN_TRANSLATE_SUCCESS,
    ADMIN_UPDATE_SETTINGS_SUCCESS,
    ADMIN_UPDATE_TRANSLATE_SUCCESS,
} from "./types";
import {
    LOCALE_FAILED,
    LOCALE_REQUEST,
    LOCALE_SUCCESS,
    SETTINGS_FAILED,
    SETTINGS_REQUEST,
    SETTINGS_SUCCESS,
} from "../types";
import { merge } from "lodash";

interface IState {
    adminLoaded: false;
    globalLoader: false;
    loaded: boolean;
    locationId: number | null;
    locations: any;
    modals: Array<{
        id: string;
    }>;
    request: any;
    snackbar: any;
    sideNavType: string;
    settings: any;
    translation: any;
    settingsLoaded: boolean;
    translationLoaded: boolean;
}
function general(
    state: IState = {
        adminLoaded: false,
        globalLoader: false,
        loaded: false,
        locationId: null,
        locations: {},
        modals: [],
        request: {},
        snackbar: null,
        sideNavType: "main",
        settings: null,
        settingsLoaded: false,
        translationLoaded: false,
        translation: null,
    },
    action = {} as AnyAction
) {
    switch (action.type) {
        case APP_INIT:
            return rs.init(state);

        case APP_LOADED:
            return {
                ...state,
                loaded: true,
            };
        case ADMIN_LOADED:
            return {
                ...state,
                adminLoaded: true,
                loaded: true,
            };
        case SETTINGS_SUCCESS:
            return {
                ...state,
                settingsLoaded: true,
                settings: merge({}, ...action.response.data.data),
            };
        case SETTINGS_REQUEST:
            return {
                ...state,
                settingsLoaded: false,
            };
        case SETTINGS_FAILED:
            return {
                ...state,
                settingsLoaded: true,
            };
        case ADMIN_UPDATE_SETTINGS_SUCCESS:
            return {
                ...state,
                settingsLoaded: false,
            };

        case ADMIN_TRANSLATE_SUCCESS:
            return {
                ...state,
                translationLoaded: true,
                translation: action.response.data.data,
            };

        case ADMIN_TRANSLATE_REQUEST:
            return {
                ...state,
                translationLoaded: false,
            };
        case ADMIN_TRANSLATE_FAILED:
            return {
                ...state,
                translationLoaded: true,
            };
        case ADMIN_UPDATE_TRANSLATE_SUCCESS:
            return {
                ...state,
                translationLoaded: false,
            };

        case LOCALE_REQUEST:
            return {
                ...state,
                globalLoader: true,
            };
        case LOCALE_FAILED:
        case LOCALE_SUCCESS:
            return {
                ...state,
                globalLoader: false,
            };

        case MODAL_HIDE:
            return {
                ...state,
                modals: state.modals.filter((modal) => modal.id !== action.id),
            };

        case MODAL_SHOW:
            return {
                ...state,
                modals: [
                    ...state.modals,
                    {
                        id: action.id,
                        props: action.props,
                    },
                ],
            };

        case SNACKBAR_HIDE:
            return {
                ...state,
                snackbar: null,
            };
        case SNACKBAR_SHOW:
            return {
                ...state,
                snackbar: action.data,
            };
        case SIDE_NAV_TYPE:
            return {
                ...state,
                sideNavType: action.data,
            };
        default:
            return state;
    }
}

export default general;
